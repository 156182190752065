<template>
  <div class="container-fluid">
    <div slot="header" class="form-header">
      <h3>{{ $t("WAREHOUSES.EDIT_WAREHOUSE") }}</h3>
    </div>
    <warehouse-form
      :loading="loading"
      :warehouseData="warehouse"
      :formErrors="formErrors"
      @warehouseSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import WarehouseForm from "../partials/WarehouseForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultWarehouse from "../defaultWarehouse";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  layout: "DashboardLayout",

  components: { WarehouseForm },

  mixins: [alertLeave, requestErrorMixin],

  props: {
    warehouseId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      warehouse: cloneDeep(defaultWarehouse),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("warehouses/get", this.warehouseId);
        this.warehouse = this.$store.getters["warehouses/warehouse"];
        this.loading = false;
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit(warehouse) {
      this.loading = true;
      const warehouseData = cloneDeep(warehouse);

      try {
        await this.$store.dispatch("warehouses/update", warehouseData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("WAREHOUSES.WAREHOUSE_UPDATED"),
        });
        const warehouse = await this.$store.getters["warehouses/warehouse"];
        this.$emit("onViewWarehouse", warehouse, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
